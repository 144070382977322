import CustomerRequest from '@requests/customer_request';
import OperationRequest from '@requests/operation_request';
import ReportRequest from '@requests/report_request';
import BranchRequest from '@requests/branch_request';
import OrderItemRequest from '@requests/order_item_request';
import InvoicePendingFeeRequest from '@requests/invoice_pending_fee_request';

export default {
    getCustomerSettlement({ commit }, filter) {
        ReportRequest.getCustomerSettlement(filter)
                        .then((data) => {
                            commit('SET_REPORTS', data);
                        })
    },
    getTotalCustomerSettlement({ commit }, filter) {
        ReportRequest.getTotalCustomerSettlement(filter)
            .then((data) => {
                commit('SET_TOTAL_REPORTS', data);
            })
    },
    downloadInvoice(_, filter) {
        ReportRequest.downloadSettleForCustomers(filter);
    },
    downloadCustomerInvoice(_, { customerId, filter, preview, meta }) {
        OrderItemRequest.downloadCustomerInvoice(customerId, filter, {
            preview,
            meta
        });
    },
    index({ commit }, filter) {
        ReportRequest.settleForCustomers(filter)
                        .then((data) => {
                            commit('SET_REPORTS', data);
                        });
    },
    settle(_, { entity, filter }) {
        return CustomerRequest.settle(entity.id, filter);
    },
    toggleHasPaidToCustomer(_, entity) {
        return OrderItemRequest.toggleHasPaidToCustomer(entity.id);
    },
    getOperations({ commit }) {
        OperationRequest.index().then(({ data }) => {
            commit('SET_OPERATIONS', data);
        });
    },
    getCustomers({ commit }, filter) {
        CustomerRequest.index(filter).then(({ data }) => {
            commit('SET_CUSTOMERS', data);
        });
    },
    getBranches({ commit }) {
        BranchRequest.index()
                    .then(({ data }) => {
                        commit('SET_BRANCHES', data);
                    });
    },
    setPendingFee(_, data) {
        return InvoicePendingFeeRequest.setPendingFee(data);
    }
}